import "./MorePost.css";
import { useState } from "react";
import { IoMdTime } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Moment from "moment";
import he from "he";

export function MorePost({ title, date, id, hideModified = false }) {
  const [hovered, setHovered] = useState(false);
  const navigate = useNavigate();

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const openPost = () => {
    if (!hideModified) {
      navigate(`/post/${id}`);
    } else {
      navigate(`/page/${id}`);
    }
  };

  const cleanTitle = he.decode(title);

  return (
    <div
      className={`more-post ${hovered ? "hover-more-post" : ""}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseUp={openPost}
    >
      <h5>{cleanTitle}</h5>
      {hideModified ? null : (
        <div className="post-time-small">
          <IoMdTime />
          <span>{Moment(date).format("DD.MM.YYYY.")}</span>
        </div>
      )}
    </div>
  );
}
