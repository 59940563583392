import "../style/Page.css"
import "./Naslovna.css"
import axios from 'axios';
import { ServiceBox } from "../components/ServiceBox"
import { Footer } from "../components/HeaderFooter/Footer"
import { HomePost } from "../components/HomePost";
import { SmallHomePost } from "../components/SmallHomePost";
import { useEffect, useState } from "react"
import { getCategory, getSlug } from "../tools/getCategory";
import { wurl } from "../tools/url";
import { Raspored } from "../components/Raspored";
import { GiGraveyard } from "react-icons/gi";
import { RiTreeFill } from "react-icons/ri";
import { BsFillTrash3Fill } from "react-icons/bs";
import { RiLeafFill } from "react-icons/ri";
import { FaWineBottle } from "react-icons/fa";
import { useNavigate, Link } from "react-router-dom";

export function Naslovna(props) {
    const [latestPosts, setLatestPosts] = useState([]);
    const [odlukePosts, setObavijestiPosts] = useState([]);
    const [natjecajiPosts, setNatjecajiPosts] = useState([]);
    const [javnaPosts, setJavnaPosts] = useState([]);
    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Za promjenu smece u zute vrece slike
    
    useEffect(()=>{
        getPosts();
        getObavijesti();
        getNatjecaji();
        getJavna();
    }, []);

    // Dohvaćanje postova za naslovnu
    const getPosts = () => {
        axios.get(`${wurl}/wp-json/wp/v2/posts?per_page=3&categories_exclude=11&orderby=date&_embed`)
        .then(response => {
            setLatestPosts(response.data);
        })
        .catch(error => console.error('There was an error fetching the posts', error));
    };

    const getObavijesti = () => {
        axios.get(`${wurl}/wp-json/wp/v2/posts?categories=3&per_page=2&orderby=date&_embed`)
        .then(response => {
            setObavijestiPosts(response.data);
        })
        .catch(error => console.error('There was an error fetching the posts', error));
    };

    const getNatjecaji = () => {
        axios.get(`${wurl}/wp-json/wp/v2/posts?categories=5&per_page=2&orderby=date&_embed`)
        .then(response => {
            setNatjecajiPosts(response.data);
        })
        .catch(error => console.error('There was an error fetching the posts', error));
    };

    const getJavna = () => {
        axios.get(`${wurl}/wp-json/wp/v2/posts?categories=4&per_page=2&orderby=date&_embed`)
        .then(response => {
            setJavnaPosts(response.data);
        })
        .catch(error => console.error('There was an error fetching the posts', error));
    };

    // Za promjenu smece u zute vrece slike
    useEffect(()=>{
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return(
        <div style={{position: 'relative'}}>
            <div className="landing">
                <video 
                    width="100%" 
                    height="600px" 
                    poster="/media/sample_fallback.jpg"
                    autoPlay={true} 
                    muted
                    loop
                    playsInline
                    style={{ height: "100%", width: "100%", objectFit: "cover"}}

                    >
                    <source src="/media/sample.mp4" type="video/mp4" />
                </video> 
                <div className="landing-content">
                    <img src='media/komunalac_logo_white.png' alt='komunalac logo'></img>
                    <span>Vizija zelene budućnosti</span>
                </div>
            </div>
            <div className="content-wrap" id="below-landing">
                <div className="services-list">
                    <ServiceBox
                        title='Zelenilo i čistoća'
                        image='media/cvijecenas.jpg'
                        body='Održavanje i uređivanje javnih površina, odvoženje i deponiranje nepropisno parkiranih automobila.'
                        target='page/654'
                    />
                    <ServiceBox
                        title='Higijena'
                        image='media/kamion.jpg'
                        body='Sakupljanje, odvoz i odlaganje otpada širom Slavonskog Broda.'
                        target='page/656'
                    />
                    <ServiceBox
                        title='Reciklažna dvorišta'
                        image='media/rec-dvo.jpg'
                        body='Kontrolirano sakupljanje, razvrstavanje i privremeno skladištenje manjih količina otpada po vrstama.'
                        target='page/658'
                    />
                    <ServiceBox
                        title='Tržnica'
                        image='media/trznica_sb.jpg'
                        body='Iznajmljivanje svih vrsta prodajnih prostora i organizacija sezonskih prodaja na uređenom i kontroliranom mjestu.'
                        target='page/660'
                    />
                    <ServiceBox
                        title='Sklonište za napuštene životinje'
                        image='media/zivotinje-home.jpg'
                        body='Učinite dobro djelo udomljavanjem napuštene životinje.'
                        target='page/662/6'
                    />
                    <ServiceBox
                        title='Groblje'
                        image='media/groblje_sb.jpg'
                        body='Obavljanje pogrebnih poslova na grobljima u Slavonskom Brodu.'
                        target='page/664'
                    />
                    <ServiceBox
                        title='Autobusni kolodvor'
                        image='media/autobusni-kolodvor.jpg'
                        body='Pronađite sve potrebne informacije o polascima i dolascima autobusa na kolodvor Slavonski Brod.'
                        target='https://aksb.hr/'
                    />
                </div>
                <div className="top-bcg">
                    <div className="rasporedi-bcg">
                        <h2>Rasporedi</h2>
                        <div className="rasporedi-container">
                            <Raspored 
                                icon={<BsFillTrash3Fill className="ricon"/>}
                                title='Raspored odvoza komunalnog otpada'
                                type='komunalni'
                            />
                            <Raspored 
                                icon={<RiLeafFill className="ricon"/>}
                                title='Raspored odvoza biootpada'
                                type='bio'
                            />
                            <Raspored 
                                icon={<FaWineBottle className="ricon"/>}
                                title='Raspored odvoza korisnog otpada'
                                type='korisni'
                            />
                            <Raspored 
                                icon={<RiTreeFill className="ricon"/>}
                                title='Raspored odvoza zelenog otpada'
                                type='zeleni'
                            />
                            <Raspored 
                                icon={<GiGraveyard className="ricon"/>}
                                title='Raspored sahrana'
                                type='sahrane'
                            />
                        </div>
                    </div>
                    <div className="news-bcg">
                    <h2>Novosti</h2>
                        <div className="news">
                            <span>Najnovije</span>
                            <div className="news-list">
                                {latestPosts.map(post => (
                                        <HomePost
                                            key={post.id}
                                            title={post.title.rendered}
                                            image={post._embedded['wp:featuredmedia'] && post._embedded['wp:featuredmedia'][0] ? (
                                                <img src={post._embedded['wp:featuredmedia'][0].source_url} alt={post.title.rendered} />
                                            ) : null}
                                            date={post.date}
                                            excerpt={post.excerpt.rendered}
                                            id={post.id}
                                            category={getCategory(post.categories[0], props.categories)}
                                            slug={getSlug(post.categories[0], props.categories)}
                                        />
                                ))}
                            </div>
                            <div className="other-news">
                                <span>Obavijesti</span>
                                <div className="other-news-column">
                                    {odlukePosts.map(post => (
                                        <SmallHomePost
                                            key={post.id}
                                            title={post.title.rendered}
                                            modified={post.date}
                                            image={post._embedded['wp:featuredmedia'] && post._embedded['wp:featuredmedia'][0] ? (
                                                <img src={post._embedded['wp:featuredmedia'][0].source_url} alt={post.title.rendered} />
                                            ) : null}
                                            slug={getSlug(post.categories[0], props.categories)}
                                            id={post.id}
                                        />
                                    ))}
                                </div>
                                <span>Natječaji</span>
                                <div className="other-news-column">
                                    {natjecajiPosts.map(post => (
                                        <SmallHomePost
                                            key={post.id}
                                            title={post.title.rendered}
                                            modified={post.date}
                                            image={post._embedded['wp:featuredmedia'] && post._embedded['wp:featuredmedia'][0] ? (
                                                <img src={post._embedded['wp:featuredmedia'][0].source_url} alt={post.title.rendered} />
                                            ) : null}
                                            slug={getSlug(post.categories[0], props.categories)}
                                            id={post.id}
                                        />
                                    ))}
                                </div>
                                <span>Javna nabava</span>
                                <div className="other-news-column">
                                    {javnaPosts.map(post => (
                                        <SmallHomePost
                                            key={post.id}
                                            title={post.title.rendered}
                                            modified={post.date}
                                            image={post._embedded['wp:featuredmedia'] && post._embedded['wp:featuredmedia'][0] ? (
                                                <img src={post._embedded['wp:featuredmedia'][0].source_url} alt={post.title.rendered} />
                                            ) : null}
                                            slug={getSlug(post.categories[0], props.categories)}
                                            id={post.id}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="kamioni-bcg">
                        <div 
                            className="kamioni-frame"
                            onMouseUp={()=>{navigate(`/map`)}}
                            >
                                <span>Prati kamione Komunalca uživo</span>
                                <div className="kamioni-button">
                                    <span>Pogledaj</span>
                                </div>
                        </div>
                    </div>
                </div>
                <div className="aplikacija-bcg">
                    <div className="aplikacija-container">
                        <img src='media/phone-full.png' alt='phone with komunalac app'></img>
                        <div className="app-info">
                            <img src='/media/logo-white.png' alt="komunalac logo" className="logo"/>
                            <span>mobilna aplikacija</span>
                            <ul>
                                <li>Obavijesti o odvozu otpada za vašu lokaciju</li>
                                <li>Čitajte najnovije obavijesti</li>
                                <li>Pratite kamione za odvoz otpada uživo</li>
                                <li>Upute o zbrinjavanju i razvrstavanju otpada</li>
                            </ul>
                            <div className="download" onClick={()=>{window.open('https://play.google.com/store/apps/details?id=com.silicabit.komunalacsb', '_blank')}}>
                                <img src='media/google-play.png' alt='google play store'></img>
                            </div>
                            <div className="download" onClick={()=>{window.open('https://apps.apple.com/hr/app/komunalac-sb/id6526483822', '_blank')}}>
                                <img src='media/app-store.png' alt='apple app store'></img>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="smece-u-zute-bcg">
                    <Link 
                        to={'https://www.slavonski-brod.hr/odvajanje-otpada'}
                        target="_blank" 
                        rel="noopener noreferrer"
                    >
                        <img 
                            src={windowWidth < 1000 ? 'media/smece-u-zute.png' : 'media/smece-u-zute-full.png'}
                            className="smece-u-zute"
                            alt="odlozite-smece-u-zute-vece"
                        />
                    </Link>
                </div>
            </div>
            <Footer/>
        </div>
    )
}