import "../style/Page.css";
import "./Map.css"
import { useState, useCallback, useEffect, useRef } from "react";
import { Footer } from "../components/HeaderFooter/Footer"
import { GoogleMap, Polyline, useJsApiLoader } from '@react-google-maps/api';
import { OverlayView, OverlayViewF } from "@react-google-maps/api"
import randomColor from "randomcolor";

// Sakrij nepotrebne Google Maps markere
const mapStyles = [
    {
      featureType: "administrative",
      elementType: "geometry",
      stylers: [{ visibility: "off" }]
    },
    {
      featureType: "poi",
      stylers: [{ visibility: "off" }]
    },
    {
      featureType: "road",
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }]
    },
    {
      featureType: "transit",
      stylers: [{ visibility: "off" }]
    }
];

const containerStyle = {
    width: '100vw',
    height: '100vh'
};

export function Map() {
    const [trucks, setTrucks] = useState({});
    const [truckImages, setTruckImages] = useState({})
    const [streetPaths, setStreetPaths] = useState([]);
    const wsRef = useRef(null);
    const center = useRef({ lat: 45.1631, lng: 18.0116 });
    const zoom = useRef(14);

    useEffect(()=>{
        window.scrollTo({top: 0, left: 0, behavior: 'instant'});
        fetchInitialTruckData().then(getRegistrationList).then(initializeWebSocket);
        
        // Load street paths
        const paths = require("./ulice.json");
        setStreetPaths(paths);
        //eslint-disable-next-line
    }, []);

    // const truckImages = {
    //     DA482HS: "1",
    //     SB256GK: "2",
    //     SB314GK: "3",
    //     SB329CK: "4",
    //     SB329GI: "5",
    //     SB486IS: "6",
    //     SB528FM: "7",
    //     SB529FM: "8",
    //     SB627HG: "9",
    //     SB630HH: "10",
    //     SB646FT: "11",
    //     SB680CG: "12",
    //     SB860HO: "13",
    //     SB935FR: "14",
    //     SB970HG: "15",
    //     SB989FV: "16",
    //     ZG7205JC: "17"
    // };
    
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBf7ulUh5y-rFtzGyKwU6RJlX2hoLs9UzI"
    });

    const onLoad = useCallback(function callback(map) {
        map.current = map;
    }, []);

    const onUnmount = useCallback(function callback(map) {
        map.current = null;
    }, []);

    const fetchInitialTruckData = async () => {
        try {
            const response = await fetch("https://komsb.silicabit.com/trucks/locations");
            const data = await response.json();
            const truckKeys = Object.keys(data);

            truckKeys.forEach((key, index) => {
                const truck = data[key];
                setTimeout(() => {
                    setTrucks(prevTrucks => ({
                        ...prevTrucks,
                        [key]: {
                            lastLocation: { latitude: truck.lat, longitude: truck.lon },
                            angle: truck.angle,
                            path: truck.past_locations.map(loc => ({ latitude: loc[0], longitude: loc[1] })),
                            label: truck.vehicle_label,
                            ignition: truck.ignition
                        }
                    }));
                }, 50 * index); // Stagger the addition of trucks
            });
        } catch (error) {
            console.error("Failed to fetch initial truck data:", error);
        }
    };

    const getRegistrationList = async () => {
        const response = await fetch("https://komsb.silicabit.com/json/vozila.json");
        const data = await response.json();
        setTruckImages(data);
    };
        
    const initializeWebSocket = useCallback(() => {
        wsRef.current = new WebSocket('wss://komsb.silicabit.com/ws/trucks');
        wsRef.current.onopen = () => console.log('WebSocket Connected');
        wsRef.current.onmessage = (event) => {
            const data = JSON.parse(event.data);
            setTrucks(prevTrucks => ({
                ...prevTrucks,
                [data.vehicle_registration]: {
                    lastLocation: { latitude: data.lat, longitude: data.lon },
                    angle: data.angle,
                    path: data.past_locations.map(loc => ({ latitude: loc[0], longitude: loc[1] })),
                    label: data.vehicle_label,
                    ignition: data.ignition
                }
            }));
        };
        wsRef.current.onerror = error => console.log('WebSocket Error:', error.message);
        wsRef.current.onclose = event => console.log('WebSocket Disconnected:', event.reason);

        return () => {
            closeWebSocket();
        };
        //eslint-disable-next-line
    }, []);

    const closeWebSocket = useCallback(() => {
        if (wsRef.current) {
            console.log('Closing WebSocket');
            wsRef.current.close();
            wsRef.current = null;
        }
    }, []);

    return(
        <div>
            <div className="map-area">
                {isLoaded && (
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center.current}
                        zoom={zoom.current}
                        options={{ styles: mapStyles }}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                    >
                        {Object.keys(trucks).map((truckId) => (
                            trucks[truckId].lastLocation && (
                                <OverlayViewF
                                    position={{
                                        lat: trucks[truckId].lastLocation.latitude,
                                        lng: trucks[truckId].lastLocation.longitude
                                    }}
                                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                    getPixelPositionOffset={(width, height) => ({
                                        x: -(width / 2),
                                        y: -height / 2,
                                    })}
                                    key={trucks[truckId].vehicle_id}
                                >
                                    <div className="marker-wrapper">
                                        <div className="marker-container">
                                            <span>{truckImages[truckId]}</span>
                                        </div>
                                        <img 
                                            src="media/dynamic_green.png"
                                            style={{transform: `rotate(${trucks[truckId].angle}deg)`}}
                                            className="marker-direction"
                                            alt="truck-direction"
                                        />
                                    </div>
                            </OverlayViewF>
                        )))}
                        {Object.keys(trucks).map((truckId) => {
                            let path_to_return = null;
                            if (trucks[truckId].hasOwnProperty("path")) {
                                const convertedPath = trucks[truckId].path.map(coords => {
                                    return { lat: coords.latitude, lng: coords.longitude };
                                });
                                path_to_return = <Polyline
                                    path={convertedPath}
                                    key={trucks[truckId].vehicle_id}
                                    options={{
                                        strokeColor: '#18ed51',
                                        strokeWeight: 2
                                    }}
                                />
                            }
                            return path_to_return;
                        })}

                        {/* Street path polylines */}
                        {streetPaths.map((street, index) => (
                            street && <Polyline
                                    path={street.coords.map(coord => ({ lat: parseFloat(coord[0]), lng: parseFloat(coord[1]) }))}
                                    key={`street-${index}`}
                                    options={{
                                        strokeColor: randomColor({format: 'rgb'}),
                                        strokeWeight: 2
                            }}
                            />
                        ))}
                    </GoogleMap>
                )}
            </div>
            <Footer/>
        </div>
    )
}