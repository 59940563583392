import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Raspored.css"

export function Raspored(props) {
    const [hovered, setHovered] = useState(false);
    const navigate = useNavigate();

    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };

    const openLink = () => {
        if (props.type !== 'sahrane' && props.type !== 'zeleni') {
            navigate(`/raspored/${props.type}`);
        } else if (props.type === 'zeleni') {
            navigate(`/page/2676`)
        } else {
            navigate(`/page/2452`)
        }
    };

    return(
        <div className={`raspored ${hovered ? 'hover-raspored' : ''}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onMouseUp={openLink}
        >
            {props.icon}
            <hr className="rsep"/>
            <span>{props.title}</span>
        </div>
    )
}