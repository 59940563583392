import "../style/Page.css";
import "./Kontakt.css"
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { Footer } from "../components/HeaderFooter/Footer";
import { useState, useEffect } from "react";
//import axios from "axios";
import { wurl } from "../tools/url";
import { useLocation } from 'react-router-dom'

export function Kontakt() {
    const location = useLocation()
    const [formData, setFormData] = useState({
        to: 'uprava@komunalac-sb.hr', // Adresa na koju se šalju kontakt mailovi
        bcc: 'krunoslav.janjic@komunalac-sb.hr', // Druga adresa na koju se šalju kontakt mailovi
        subject: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    }

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'instant'});
    }, [location.pathname]);

    const options = [
        'Zelenilo i čistoća', 'Higijena', 'Reciklažno dvorište', 'Tržnica', 'Sklonište za napuštene životinje', 'Groblje'
    ];
    const defaultOption = options[-1];
    const [whoToContact, setWhoToContact] = useState(defaultOption);

    const onDropdown = (event) => {
        setWhoToContact(event.label);
        console.log(whoToContact);
    } ;

    const handleSubmit = async (e) => {
        e.preventDefault();
        let errorMessage = '';
        if (whoToContact === '') {
            errorMessage += "Morate odabrati koga želite kontaktirati!\n"
        };
        if (formData.subject === '') {
            errorMessage += "Naslov ne smije biti prazan!\n"
        };
        if (formData.email === '') {
            errorMessage += "Email ne smije biti prazan!\n"
        };
        if (formData.message === '') {
            errorMessage += "Sadržaj poruke ne smije biti prazan!\n"
        };


        if (errorMessage === '') {
            const combinedName = whoToContact + " - " + formData.subject;
            const combinedMessage = `Poruku poslao/la: ${formData.email}\nPoruka:\n${formData.message}`
            const formToSend = {...formData, subject: combinedName, message: combinedMessage};

            const response = await fetch(`${wurl}/wp-json/app/v1/contact`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formToSend),
            });

            if (response.ok) {
                console.log("Email sent successfully");
            } else {
                console.error("Failed to send email");
            }

            setFormData({
                to: 'uprava@komunalac-sb.hr',
                bcc: 'krunoslav.janjic@komunalac-sb.hr',
                email: '',
                subject: '',
                message: ''
            });
            setWhoToContact(options[-1]);

            alert("Hvala na javljanju! Kontaktirat ćemo Vas uskoro!");
        } else {
            alert(errorMessage);
        };
    }
    
    return(
        <div>
            <div className="content-wrap">
                <div className="page-content contact-content">
                    <img src="media/contact.jpg" alt="phone contact" className="contact-image"/>
                    <div className="contact-form">
                        <h2>Kontakt</h2>
                        <form onSubmit={handleSubmit}>
                            <Dropdown options={options} onChange={onDropdown} value={whoToContact} placeholder="Želim kontaktirati ..."/>
                            <input type="text" placeholder="Naslov" name="subject" value={formData.subject} onChange={handleChange} className="contact-input"/>
                            <input type="email" placeholder="Vaš email" name="email" value={formData.email} onChange={handleChange} className="contact-input"/>
                            <textarea type="text" placeholder="Sadržaj poruke" name="message" value={formData.message} onChange={handleChange} className="contact-input message"/>
                            <button className="contact-button" type="submit">Pošalji</button>
                        </form>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}