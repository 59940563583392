import { useState } from "react"
import { useNavigate } from "react-router-dom";
import "./AnimalPost.css"
import he from 'he';

export function AnimalPost(props) {
    const [hovered, setHovered] = useState(false);
    const navigate = useNavigate();

    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };

    const openPost = () => {
        navigate(`/post/${props.id}`);
    };

    const title = he.decode(props.title);
    //const previewText = props.excerpt.replace(/<\/?[^>]+(>|$)/g, "");

    return(
        <div 
            className={`animal-post-wrapper ${hovered ? 'hover-post' : ''}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onMouseUp={openPost}
            >
            {
                props.image !== null ?
                <img src={props.image.props.src} alt='Post featured'/> :
                <img src={`media/${props.slug}.jpg`} alt='Post featured'/>
            }
            <div className={`animal-content-wrapper`}>
                <h3>{title}</h3>
            </div>
        </div>
    );
}