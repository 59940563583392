import "./style/CSSReset.css"
import "./style/General.css"
import { Route, Routes } from 'react-router-dom';
import { Header } from "./components/HeaderFooter/Header";
import { Naslovna } from "./pages/Naslovna"
import { Page } from "./pages/Page";
import { PostPage } from "./pages/PostPage"
import { Post } from "./pages/Post";
import { Kontakt } from "./pages/Kontakt";
import { Raspored } from "./pages/Raspored";
import { SearchResults } from "./pages/SearchResults";
import { Map } from "./pages/Map";
import { useEffect, useState } from "react";
import { wurl } from "./tools/url";


function App() {
  // Get categories
  const [categories, setCategories] = useState([]);

  useEffect(()=>{
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${wurl}/wp-json/wp/v2/categories`);
        if (!response.ok) {
          throw new Error('Could not fetch categories');
        }
        const data = await response.json();
        setCategories(data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchCategories();
  }, []);

  return (
    <div className="App">
      <Header/>
      <Routes>
        <Route path="/" element={ <Naslovna categories={categories}/> } />
        <Route path="/post-page" element={ <PostPage categories={categories}/> } />
        <Route path="/post/:id" element={ <Post categories={categories}/> } />
        <Route path="/page/:id/:category?" element={ <Page categories={categories}/> } />
        <Route path="/kontakt" element={ <Kontakt/> } />
        <Route path="/searchresults/:query" element={ <SearchResults/> } />
        <Route path="/raspored/:type" element={ <Raspored/> } />
        <Route path="/map" element={ <Map/> } />
      </Routes>
    </div>
  );
}

export default App;
