import "./SmallHomePost.css"
import { useState } from "react"
import { IoMdTime } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Moment from 'moment';
import he from 'he';
import { cutText } from "../tools/cutText";

export function SmallHomePost(props) {
    const [hovered, setHovered] = useState(false);
    const navigate = useNavigate();

    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };

    const openPost = () => {
        navigate(`/post/${props.id}`);
    };

    const title = he.decode(props.title);

    return(
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onMouseUp={openPost}
            className={`smallhome-post-wrapper ${hovered ? 'hover-post' : ''}`}
        >
            {
                props.image !== null ?
                <img src={props.image.props.src} alt='Post featured'/> :
                <img src={`media/${props.slug}.jpg`} alt='Post featured'/>
            }
            <div className="smallhome-text">
                <h5>{cutText(title, 140)}</h5>
                <div className="post-time-small">
                    <IoMdTime />
                    <span>{Moment(props.modified).format('DD.MM.YYYY.') }</span>
                </div>
            </div>
        </div>
    )
}