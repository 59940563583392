export function getCategory(category, categories) {

    for (let i = 0; i < categories.length; i++) {
        if (categories[i].id === category) {
            return(categories[i].name)
        }
    }
}

export function getSlug(category, categories) {

    for (let i = 0; i < categories.length; i++) {
        if (categories[i].id === category) {
            return(categories[i].slug)
        }
    }
}

