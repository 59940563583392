export function StreetFound(props) {
    const numToDay = (n) => {
        switch (n) {
            case 1:
                return 'ponedeljkom';
            case 2:
                return 'utorkom';
            case 3:
                return 'srijedom';
            case 4:
                return 'četvrtkom';
            default:
                return 'petkom';
        }
    }

    const numToDay2 = (n) => {
        switch (n) {
            case 1:
                return 'ponedeljk';
            case 2:
                return 'utorak';
            case 3:
                return 'srijeda';
            case 4:
                return 'četvrtak';
            default:
                return 'petak';
        }
    }

    const numToWeek = (n) => {
        switch (n) {
            case 1:
                return 'prvi';
            case 2:
                return 'drugi';
            case 3:
                return 'treći';
            default:
                return 'četvrti';
        }
    }
    
    return (
        <div className="street-search-result">
            <div>{props.name}:</div>
            <div>
                Komunalni otpad se odvozi {numToDay(props.komunalni['dan'])}.
            </div>
            <div>
                Bio otpad se odvozi {numToDay(props.bio['dan'])}.
            </div>
            <div>
                Korisni otpad se odvozi {numToWeek(props.korisni['tjedan'][0])} i {numToWeek(props.korisni['tjedan'][1])} {numToDay2(props.korisni['dan'])} u mjesecu.
            </div>
        </div>
    )
};