import "../style/page/wpage.css";
import "../style/Page.css";
import "./Post.css";
import { IoMdTime } from "react-icons/io";
import Moment from "moment";
import { useParams } from "react-router-dom";
import { MorePost } from "../components/MorePost";
import { Footer } from "../components/HeaderFooter/Footer";
import { useEffect, useState } from "react";
import { wurl } from "../tools/url";
import { getSlug } from "../tools/getCategory";
import { Share } from "../components/Share";
import { GridGallery } from "../components/GridGallery";
import he from "he";

export function Post(props) {
  let { id } = useParams();
  const [post, setPost] = useState({});
  const [morePosts, setMorePosts] = useState({});
  const [loading, setLoading] = useState(true);
  const [moreLoading, setMoreLoading] = useState(true);
  const [images, setImages] = useState([]);

  useEffect(() => {
    getPost();
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    getPost();
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    //eslint-disable-next-line
  }, [id]);

  const getPost = async () => {
    // Get main post

    try {
      const response = await fetch(`${wurl}/wp-json/wp/v2/posts/${id}?_embed`);
      if (!response.ok) {
        throw new Error("Post not found");
      }
      const data = await response.json();
      const responseHTML = data.content.rendered;
      const imagesFromGallery = extractImageUrls(responseHTML);
      setImages(imagesFromGallery);
      setPost(data);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
    }

    // Get more posts
    try {
      const response = await fetch(
        `${wurl}/wp-json/wp/v2/posts?per_page=5&categories_exclude=11&_embed`
      );
      if (!response.ok) {
        throw new Error("More posts not found");
      }
      const moreData = await response.json();
      console.log(moreData);
      setMorePosts(moreData);
      setMoreLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getCategoryImage = (category) => {
    const cimg = getSlug(category, props.categories);
    return (
      <img
        src={`/media/${cimg}.jpg`}
        className="featured-image"
        alt="featured background"
      />
    );
  };

  const extractImageUrls = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
    const imageElements = doc.querySelectorAll(
      ".wp-block-gallery > .wp-block-image > img"
    );
    const imageUrls = Array.from(imageElements).map((img) => ({
      original: img.src,
      thumbnail: img.src,
    }));
    return imageUrls;
  };

  function wrapTableWithDiv(htmlString) {
    // Regular expression to match the opening <table tag
    const tableOpenTagRegex = /<table\b/gi;
    // Regular expression to match the closing </table> tag
    const tableCloseTagRegex = /<\/table>/gi;

    // Add the opening div before the <table> tag
    let modifiedHtmlString = htmlString.replace(
      tableOpenTagRegex,
      '<div class="table-overflow-wrapper">$&'
    );
    // Add the closing div after the </table> tag
    modifiedHtmlString = modifiedHtmlString.replace(
      tableCloseTagRegex,
      "$&</div>"
    );

    return modifiedHtmlString;
  }

  return (
    <div>
      {!loading && (
        <div>
          <div className="content-wrap">
            <div className="post-wrapper">
              {
                post.categories[0] !== 6 ? (
                  post._embedded["wp:featuredmedia"] &&
                  post._embedded["wp:featuredmedia"][0] !== null ? (
                    <img
                      src={post._embedded["wp:featuredmedia"][0].source_url}
                      className="featured-image"
                      alt="featured background"
                    ></img>
                  ) : (
                    getCategoryImage(post.categories[0])
                  )
                ) : (
                  <img
                    src="/media/zivotinje-top.jpg"
                    className="featured-image"
                    alt="featured background"
                  ></img>
                ) // Zivotinje header
              }
              <div className="post-time">
                <IoMdTime />
                <span>{Moment(post.date).format("DD.MM.YYYY.")}</span>
              </div>
              <div className="column-split">
                <div className="post-content">
                  <h1 className="post-title">
                    {he.decode(post.title.rendered)}
                  </h1>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: wrapTableWithDiv(post.content.rendered),
                    }}
                  />
                  {images.length > 0 && (
                    //<ReactImageGallery items={images} additionalClass="real-gallery"/>
                    <GridGallery images={images} />
                  )}
                </div>
                <Share url={window.location.href} />
                <div className="more-posts">
                  <h4>Aktualno</h4>
                  {!moreLoading &&
                    morePosts.map((morePost) => (
                      <MorePost
                        key={morePost.id}
                        title={morePost.title.rendered}
                        date={morePost.date}
                        id={morePost.id}
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
}
