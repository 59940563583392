import "./GridGallery.css"
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css'

export function GridGallery(props){
    console.log("gallery");
    console.log(props.images);

    return(
        <div className="grid-gallery">
            {props.images.map(image => {
                return(
                    <Zoom>
                        <img
                            key={image.original}
                            alt=""
                            src={image.original}
                            className="grid-gallery-image"
                        />
                    </Zoom>
                )
            })}
        </div>
    )
}