import { useState } from "react"
import { Link } from "react-router-dom";
import "./ServiceBox.css"

export function ServiceBox(props) {
    const [hovered, setHovered] = useState(false);

    const handleMouseEnter = () => {
        setHovered(true);
    };
  
    const handleMouseLeave = () => {
      setHovered(false);
    };

    return(
        <div className={hovered ? "sb-container hover" : "sb-container"} 
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            >
            <img src={props.image} alt='service background'/>
            <div className="background"></div>
            <h3>{props.title}</h3>
            <div>
                <p>{props.body}</p>
                <Link to={props.target} className="sb-button">Više</Link>
            </div>
        </div>
    )
}