import { useEffect, useRef, useState } from "react";
import { useLocation, useParams, Link } from "react-router-dom";
import { Footer } from "../components/HeaderFooter/Footer";
import { MorePost } from "../components/MorePost";
import { PostPageNavigation } from "../components/PostPageNavigation";
import { AnimalPost } from "../components/AnimalPost";
import "../style/page/wpage.css";
import "../style/Page.css";
import { wurl } from "../tools/url";
import "../style/wordpress-override/ZeleniloCistoca.css";
import "react-image-gallery/styles/css/image-gallery.css";
import { getSlug } from "../tools/getCategory";
import axios from "axios";
import PuffLoader from "react-spinners/PuffLoader";
import "react-medium-image-zoom/dist/styles.css";
import { GridGallery } from "../components/GridGallery";
import { CustomZoom } from "../components/CustomZoom";

export function Page(props) {
  let { id, category } = useParams();
  const [page, setPage] = useState(null);
  const [posts, setPosts] = useState([]);
  const [osmrtnice, setOsmrtnice] = useState([]);
  const postsCurrentPage = useRef(1);
  const postsTotalPages = useRef();

  const [loading, setLoading] = useState(true);
  const [loadingPosts, setLoadingPosts] = useState(true);
  const [loadingOsmrtnice, setLoadingOsmrtnice] = useState(true);
  const [images, setImages] = useState([]);
  const location = useLocation();

  const [selectedImage, setSelectedImage] = useState(null);

  const [formData, setFormData] = useState({
    to: "uprava@komunalac-sb.hr", // Adresa na koju se šalju kontakt mailovi
    bcc: "krunoslav.janjic@komunalac-sb.hr", // Druga adresa na koju se šalju kontakt mailovi
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errorMessage = "";
    if (formData.name === "") {
      errorMessage += "Ime i prezime/Tvrtka ne smije biti prazan!\n";
    }
    if (formData.email === "") {
      errorMessage += "Email ne smije biti prazan!\n";
    }
    if (formData.phone === "") {
      errorMessage += "Telefon ne smije biti prazan!\n";
    }
    if (/^\d+$/.test(formData.phone) === false) {
      errorMessage += "Telefon smije sadržavati samo brojeve!\n";
    }
    if (formData.subject === "") {
      errorMessage += "Predmet poruke ne smije biti prazan!\n";
    }
    if (formData.message === "") {
      errorMessage += "Sadržaj poruke ne smije biti prazan!\n";
    }

    if (errorMessage === "") {
      const combinedName = "Prigovor - " + formData.subject;
      const combinedMessage = `Poruku poslao/la: ${formData.email}\nTelefon: ${formData.phone}\nNaslov poruke: ${formData.subject}\nPoruka:\n${formData.message}`;
      const formToSend = {
        ...formData,
        subject: combinedName,
        message: combinedMessage,
      };

      console.log(formToSend);

      const response = await fetch(`${wurl}/wp-json/app/v1/prigovor`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formToSend),
      });

      if (response.ok) {
        console.log("Email sent successfully");
      } else {
        console.error("Failed to send email");
      }

      setFormData({
        to: "uprava@komunalac-sb.hr", // Adresa na koju se šalju kontakt mailovi
        bcc: "krunoslav.janjic@komunalac-sb.hr", // Druga adresa na koju se šalju kontakt mailovi
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      });

      alert("Hvala na javljanju! Kontaktirat ćemo Vas uskoro!");
    } else {
      alert(errorMessage);
    }
  };

  useEffect(() => {
    // Raspored sahrana
    if (id === "2452" || id === "2676") {
      // Custom table CSS za sahrane raspored, inače se kolje sa rasporedima odvoza
      const sahraneRaspored = document.createElement("style");
      sahraneRaspored.type = "text/css";
      sahraneRaspored.appendChild(
        document.createTextNode(
          `tr:first-child {
                background-color: #dadada;
                border-bottom: 1px solid var(--gray) !important;
            }
            
            tr:nth-child(odd) {
                background-color: var(--white-darker) !important;
            }`
        )
      );

      document.head.appendChild(sahraneRaspored);

      // Dohvati osmrtnice
      getOsmrtnice();

      return () => {
        document.head.removeChild(sahraneRaspored);
      };
    } else if (id === "2825") {
      // Custom CSS za stranicu Kompostane
      const kompostanaCSS = document.createElement("style");
      kompostanaCSS.type = "text/css";
      kompostanaCSS.appendChild(
        document.createTextNode(`
            figure:first-of-type > div > table {
                border: none;
                margin-bottom: 3rem;
            }

            figure:first-of-type > div > table td:first-child,
            figure:first-of-type > div > table th:first-child {
                width: 300px;
            }
                
            figure:nth-of-type(2) > div > table {
                border: none;
            }

            figure:nth-of-type(2) > div > table > tbody > tr > td:first-of-type {
                width: 80px;
                height: auto;
            }

            figure:nth-of-type(2) > div > table > tbody > tr > td > img {
                width: 80px;
                height: auto;
                object-fit: cover;
            }
                
            figure:nth-of-type(3) > div > table {
                border: none;
            }

            figure:nth-of-type(3) > div > table > tbody > tr > td:first-of-type {
                width: 80px;
                height: auto;
            }

            figure:nth-of-type(3) > div > table > tbody > tr > td > img {
                width: 80px;
                height: auto;
                object-fit: cover;
            }
            
            figure:nth-of-type(4) > div > table {
                border: none;
            }

            figure:nth-of-type(4) > div > table > tbody > tr > td:first-of-type {
                width: 80px;
                height: auto;
            }

            figure:nth-of-type(4) > div > table > tbody > tr > td > img {
                width: 80px;
                height: auto;
                object-fit: cover;
            }

            figure:nth-of-type(5) > img {
                width: 500px;
            }

            @media only screen and (max-width: 1000px) {
                figure:first-of-type > div > table td:first-child,
                figure:first-of-type > div > table th:first-child {
                    width: auto;
                }
            }
            `)
      );

      document.head.appendChild(kompostanaCSS);

      return () => {
        document.head.removeChild(kompostanaCSS);
      };
    }
    //eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    postsCurrentPage.current = 1;
    setLoading(true);
    setLoadingPosts(true);
    setPage(null);
    setPosts([]);
    fetchPage();
    if (category !== undefined) {
      fetchPostsByCategory(category);
    }
    //eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [page]);

  const goto = (p) => {
    postsCurrentPage.current = p;
    fetchPostsByCategory(category);
  };

  const fetchPage = async () => {
    try {
      const response = await fetch(`${wurl}/wp-json/wp/v2/pages/${id}`);
      if (!response.ok) {
        throw new Error("Page not found");
      }
      const data = await response.json();
      const responseHTML = data.content.rendered;
      const imagesFromGallery = extractImageUrls(responseHTML);

      setImages(imagesFromGallery);
      setPage(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchPostsByCategory = async (categoryId) => {
    if (categoryId !== "6") {
      try {
        // Not animals
        const response = await fetch(
          `${wurl}/wp-json/wp/v2/posts?categories=${categoryId}&per_page=10&page=${postsCurrentPage.current}&_embed`
        );
        postsTotalPages.current = parseInt(
          response.headers.get("x-wp-totalpages"),
          10
        );
        if (!response.ok) {
          throw new Error("Posts not found");
        }
        const data = await response.json();
        setPosts(data);
        setLoadingPosts(false);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        // List more than 10 posts if it's animals
        // Step 1: Get total number of posts for the category
        const response = await axios.get(
          `${wurl}/wp-json/wp/v2/posts?categories=${categoryId}&per_page=1`
        );
        const totalPosts = parseInt(response.headers["x-wp-total"], 10);

        // Calculate the number of pages needed (100 posts per page is a common limit)
        const perPage = 100;
        const totalPages = Math.ceil(totalPosts / perPage);

        // Step 2: Fetch all posts (handling pagination)
        const postsPromises = [];
        for (let page = 1; page <= totalPages; page++) {
          postsPromises.push(
            axios.get(
              `${wurl}/wp-json/wp/v2/posts?categories=${categoryId}&per_page=${perPage}&page=${page}&_embed`
            )
          );
        }

        const postsResponses = await Promise.all(postsPromises);
        const allPosts = postsResponses.flatMap((response) => response.data);
        setPosts(allPosts);
        setLoadingPosts(false);
      } catch (error) {
        console.error("Failed to fetch posts:", error);
      }
    }
  };

  const extractImageUrls = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
    const imageElements = doc.querySelectorAll(
      ".wp-block-gallery > .wp-block-image > img"
    );
    const imageUrls = Array.from(imageElements).map((img) => ({
      original: img.src,
      thumbnail: img.src,
    }));
    return imageUrls;
  };

  const lessThen = (n) => {
    if (n <= 1) {
      return 1;
    } else {
      return n - 1;
    }
  };

  const moreThen = (n) => {
    if (n >= postsTotalPages.current) {
      return postsTotalPages.current;
    } else {
      return n + 1;
    }
  };

  const getOsmrtnice = async () => {
    try {
      const currentYear = new Date().getFullYear();
      let allPosts = [];
      let page = 1;
      let totalPages = 1;

      while (page <= totalPages) {
        const response = await fetch(
          `${wurl}/wp-json/wp/v2/posts?categories=11&orderby=date&after=${currentYear}-01-01T00:00:00&before=${currentYear}-12-31T23:59:59&page=${page}&_embed`
        );
        if (!response.ok) {
          throw new Error(`Error fetching posts: ${response.statusText}`);
        }
        const data = await response.json();
        const totalPagesHeader = response.headers.get("X-WP-TotalPages");
        totalPages = totalPagesHeader ? parseInt(totalPagesHeader, 10) : 1;

        allPosts = [...allPosts, ...data];
        page += 1;
      }

      setOsmrtnice(allPosts);
      setLoadingOsmrtnice(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  const pageImages = {
    2449: `/media/obavijesti.jpg`,
    646: `/media/odluke.jpg`,
    648: `/media/javna-nabava-top.jpg`,
    650: `/media/natjecaji.jpg`,
    652: `/media/zaposljavanje-top.jpg`,
    654: `/media/zelenilo-i-cistoca.jpg`,
    656: `/media/higijena.jpg`,
    658: `/media/reciklazna-dvorista.jpg`,
    660: `/media/trznica-top.jpg`,
    662: `/media/skloniste-za-zivotinje.jpg`,
    664: `/media/groblje.jpg`,
    2454: `/media/groblje.jpg`, // Groblja, pogrebne i klesarske usluge
    2456: `/media/groblje.jpg`, // Pogrebna oprema, vijenci i aranžmani
    2458: `/media/groblje.jpg`, // Postupak kod smrtnog slučaja
    2460: `/media/groblje.jpg`, // Organizacija i tijek obreda posljednjeg ispraćaja
    2452: `/media/groblje.jpg`, // Raspored sahrana
    666: `/media/cjenici-usluga.jpg`,
    668: `/media/eu-projekti.jpg`,
    2825: `/media/eu-projekti.jpg`, // Kompostana
    670: `/media/planovi-i-izvjesca.jpg`,
    672: `/media/pravo-na-pristup-informacijama.jpg`,
    674: `/media/odluke.jpg`,
    676: `/media/o-nama.jpg`,
    678: `/media/povijest.jpg`,
    680: `/media/vizija-i-ciljevi.jpg`,
    682: `/media/opci-podaci-o-tvrtki.jpg`,
    684: `/media/politika-kvalitete.jpg`,
    686: `/media/opci-akti-drustva.jpg`,
    2673: `/media/obavijesti.jpg`,
    2676: `/media/zeleni-otpad.jpg`,
  };

  function wrapTableWithDiv(htmlString) {
    // Regular expression to match the opening <table> tag with or without attributes
    const tableOpenTagRegex = /<table\b[^>]*>/gi;
    // Regular expression to match the closing </table> tag
    const tableCloseTagRegex = /<\/table>/gi;

    // Add the opening div before the <table> tag
    let modifiedHtmlString = htmlString.replace(
      tableOpenTagRegex,
      '<div class="table-overflow-wrapper">$&'
    );
    // Add the closing div after the </table> tag
    modifiedHtmlString = modifiedHtmlString.replace(
      tableCloseTagRegex,
      "$&</div>"
    );

    return modifiedHtmlString;
  }

  const setImageZoom = (img) => {
    setSelectedImage(img);
    document.body.style.overflow = "hidden";
  };

  const dismissImageZoom = () => {
    setSelectedImage(null);
    document.body.style.overflow = "auto";
  };

  return (
    <div>
      <div className="content-wrap">
        {!loading ? (
          <div>
            {
              <img
                className="page-top-image"
                src={pageImages[id]}
                alt="page-header"
              />
            }
            <div className="page-content">
              {selectedImage !== null ? (
                <CustomZoom image={selectedImage} dismiss={dismissImageZoom} />
              ) : null}
              {id === "664" ? (
                <Link
                  to="https://egroblje.komunalac-sb.hr/#/index"
                  className="link-egroblje"
                >
                  E-Groblje
                </Link>
              ) : null}
              <h1>{page?.title?.rendered}</h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: wrapTableWithDiv(page?.content?.rendered),
                }}
              ></div>
              {images.length > 0 && (
                //<ReactImageGallery items={images} additionalClass="real-gallery"/>
                <GridGallery images={images} />
              )}
              {category !== "6" ? (
                <div className="more-posts-list">
                  {!loadingPosts
                    ? posts.map((post) => (
                        <MorePost
                          key={post.id}
                          title={post.title.rendered}
                          date={post.date}
                          id={post.id}
                        />
                      ))
                    : null}
                  {posts.length > 1 && postsTotalPages.current > 1 ? (
                    <div className="post-navigation-container">
                      <PostPageNavigation text="<<" click={() => goto(1)} />
                      <PostPageNavigation
                        text="<"
                        click={() => goto(lessThen(postsCurrentPage.current))}
                      />
                      <span>
                        {postsCurrentPage.current}/{postsTotalPages.current}
                      </span>
                      <PostPageNavigation
                        text=">"
                        click={() => goto(moreThen(postsCurrentPage.current))}
                      />
                      <PostPageNavigation
                        text=">>"
                        click={() => goto(postsTotalPages.current)}
                      />
                    </div>
                  ) : null}
                </div>
              ) : (
                <div className="animals-posts-list">
                  {!loadingPosts ? (
                    posts.map((post) => (
                      <AnimalPost
                        key={post.id}
                        title={post.title.rendered}
                        modified={"/"}
                        id={post.id}
                        image={
                          post._embedded["wp:featuredmedia"] &&
                          post._embedded["wp:featuredmedia"][0] ? (
                            <img
                              src={
                                post._embedded["wp:featuredmedia"][0].source_url
                              }
                              alt={post.title.rendered}
                            />
                          ) : null
                        }
                        excerpt={post.excerpt.rendered}
                        slug={getSlug(post.categories[0], props.categories)}
                      />
                    ))
                  ) : (
                    <PuffLoader
                      color="#009846"
                      loading={loadingPosts}
                      cssOverride={{
                        display: "block",
                        margin: "0 auto",
                        borderColor: "#009846",
                      }}
                      size={150}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  )}
                </div>
              )}
              {id === "664" ? (
                <div>
                  <MorePost
                    title="Raspored sahrana"
                    modified=""
                    id={2452}
                    hideModified={true}
                  />
                  <MorePost
                    title="Groblja, pogrebne i klesarske usluge"
                    modified=""
                    id={2454}
                    hideModified={true}
                  />
                  <MorePost
                    title="Pogrebna oprema, vijenci i aranžmani"
                    modified=""
                    id={2456}
                    hideModified={true}
                  />
                  <MorePost
                    title="Postupak kod smrtnog slučaja"
                    modified=""
                    id={2458}
                    hideModified={true}
                  />
                  <MorePost
                    title="Organizacija i tijek obreda posljednjeg ispraćaja"
                    modified=""
                    id={2460}
                    hideModified={true}
                  />
                </div>
              ) : null}
              {/* Osmrtnice */}
              {id === "2452" ? (
                <div className="osmrtnice-list">
                  {!loadingOsmrtnice
                    ? osmrtnice.map((osmrtnica) => {
                        return (
                          <img
                            key={osmrtnica.id}
                            alt={osmrtnica.slug}
                            src={
                              osmrtnica._embedded["wp:featuredmedia"][0]
                                .source_url
                            }
                            className="osmrtnica"
                            onClick={() => {
                              setImageZoom(
                                osmrtnica._embedded["wp:featuredmedia"][0]
                                  .source_url
                              );
                            }}
                          />
                        );
                      })
                    : null}
                </div>
              ) : null}
              {/* Podnošenje prigovora */}
              {id === "2673" ? (
                <div className="podnosenje-prigovora">
                  <h2>Obratite nam se</h2>
                  <span>
                    Za sve upite i informacije stojimo na raspolaganju!
                  </span>
                  <div className="prigovor-wrapper">
                    <div className="prigovor-info">
                      <span className="prigovor-info-text prigovor-info-bold">
                        Pisani zahtjev podnosi se na adresu:
                      </span>
                      <span className="prigovor-info-text">
                        Komunalac d.o.o.
                      </span>
                      <span className="prigovor-info-text">
                        Stjepana pl. Horvata 38
                      </span>
                      <span className="prigovor-info-text">
                        35000 Slavonski Brod
                      </span>
                      <span className="prigovor-info-text prigovor-info-bold prigovor-margin-top">
                        Elektronske pošte:
                      </span>
                      <a
                        href="mailto:someone@example.com"
                        className="prigovor-info-text prigovor-link"
                      >
                        uprava@komunalac-sb.hr
                      </a>
                      <span className="prigovor-info-text">
                        telefax: 035/ 443 740
                      </span>
                      <span className="prigovor-info-text prigovor-info-bold prigovor-margin-top">
                        Usmeno postavljeni zahtjev prima službenik za
                        informiranje:
                      </span>
                      <span className="prigovor-info-text">
                        radnim danom od 8:00 do 13:00 sati
                      </span>
                      <span className="prigovor-info-text">
                        Stjepana pl. Horvata 38, Slavonski Brod
                      </span>
                      <span className="prigovor-info-text">
                        Telefon: 035/ 492 217
                      </span>
                      <span className="prigovor-info-text prigovor-margin-top">
                        Prilog:
                      </span>
                      <a
                        href={`${wurl}/wp-content/uploads/2024/05/Zahtijev-za-pristup-informacijama.doc`}
                        className="prigovor-info-text prigovor-link"
                      >
                        Zahtjev za pristup informacijama
                      </a>
                    </div>
                    <div className="prigovor-forma">
                      <form onSubmit={handleSubmit}>
                        <input
                          type="text"
                          placeholder="Ime i prezime/Tvrtka"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          className="prigovor-text-input"
                        />
                        <input
                          type="email"
                          placeholder="Vaš email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          className="prigovor-text-input"
                        />
                        <input
                          type="text"
                          placeholder="Telefon"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                          className="prigovor-text-input"
                        />
                        <input
                          type="text"
                          placeholder="Predmet poruke"
                          name="subject"
                          value={formData.subject}
                          onChange={handleChange}
                          className="prigovor-text-input"
                        />
                        <textarea
                          type="text"
                          placeholder="Vaša poruka"
                          name="message"
                          value={formData.message}
                          onChange={handleChange}
                          className="prigovor-text-input prigovor-poruka"
                        />
                        <button className="contact-button" type="submit">
                          Pošalji
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
      <Footer />
    </div>
  );
}
