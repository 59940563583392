import React, { useEffect, useState } from 'react';
import axios from 'axios';
import createDOMPurify from 'dompurify'
import { Footer } from "../components/HeaderFooter/Footer"
import "../style/Page.css"
import { wurl } from '../tools/url';

export function PostPage() {
    const [posts, setPosts] = useState([]);
    const DOMPurify = createDOMPurify(window);

    useEffect(() => {
        axios.get(`${wurl}/wp-json/wp/v2/posts`)
        .then(response => {
            setPosts(response.data);
        })
        .catch(error => console.error('There was an error!', error));
    }, []);

    return(
        <div>
            <div className="content-wrap">
                <h1>Latest Posts</h1>
                <ul>
                    {posts.map(post => (
                    <li key={post.id}>
                        <h2 dangerouslySetInnerHTML={{__html: post.title.rendered}}></h2>
                        <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(post.content.rendered)}} />
                    </li>
                    ))}
                </ul>
            </div>
            <Footer/>
        </div>
    )
}